import $ from 'jquery';
import 'select2';

const selectors = {
  gameSelect: 'select#explorer-dropdown',
};

document.addEventListener("turbolinks:load", () => {
  const gameSelect = document.querySelector(selectors.gameSelect);
  if (!gameSelect) { return; }

  $(gameSelect).select2({
    theme: "bootstrap",
    placeholder: "Type to search..."
  });

  $(gameSelect).on('select2:select', (event) => {
    const selectedOption = event.target.children[event.target.selectedIndex];
    window.location.href = selectedOption.dataset.url;
  });
});
