import $ from 'jquery';
import 'select2';

const selectors = {
  iconSelect: '.icon-select',
}

document.addEventListener("turbolinks:load", () => {
  initIconSelect();
})

function initIconSelect() {
  const iconSelect = document.querySelector(selectors.iconSelect);
  if (!iconSelect) { return; }

  $(iconSelect).select2({
    theme: "bootstrap",
    templateResult: (option) => { return (option.title ? $(option.title) : option.text); }
  });
};
