document.addEventListener("turbolinks:load", () => {
	// Helper to add JS links to table rows.
	const linkedTableRows = document.querySelectorAll('tr[data-link]');
	const messagingLinks = document.querySelectorAll('.messaging-link');
	// Helper to add JS links to table rows.
	linkedTableRows.forEach((el) => {
		el.addEventListener("click", () => {
			window.location = el.dataset.link;
		});
	});

	//menu link helper to open firebase links in new window in addition to actual interal page link.
	messagingLinks.forEach((el) => {
		el.addEventListener("click", function () {
			const newMessageLink = el.getAttribute('data-url');
			window.open(newMessageLink, '_blank');
		});
	});
});