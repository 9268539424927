import Rails from "@rails/ujs"

// Table checkboxes behavior is implemented with the theme:
// https://prium.github.io/falcon/v3.0.0/modules/tables/bulk-select.html
// The theme requires `data-bulk-select` attribute for the "Select All" checkbox, and
// `data-bulk-select-row` attribute for each individual check box.

const selectors = {
  bulkActionsContainer: "#bulk-actions",
  alertContainer: "#bulk-actions .alert",
  selectAllCheckbox: "input[data-bulk-select]",
  checkedItemCheckbox: "input[data-bulk-select-row]:checked",
};

document.addEventListener("turbolinks:load", () => {
  const bulkActionsContainer = document.querySelector(selectors.bulkActionsContainer);
  if (!bulkActionsContainer) { return; }

  const applyButton = bulkActionsContainer.querySelector('button.apply');
  applyButton.addEventListener("click", (event) => {
    event.preventDefault();
    submitBulkAction();
  });
});

function submitBulkAction() {
  const selectAllCheckbox = document.querySelector(selectors.selectAllCheckbox);
  if (!selectAllCheckbox) { return; }

  const submitUrl = selectAllCheckbox.dataset.bulkSubmitUrl;

  // These selectors are defined as data attributes according to the theme docs:
  // https://prium.github.io/falcon/v3.0.0/modules/tables/bulk-select.html
  const bulkSelectors = JSON.parse(selectAllCheckbox.dataset.bulkSelect);
  const checkboxContainer = document.getElementById(bulkSelectors.body);
  const actionContainer = document.getElementById(bulkSelectors.actions);

  const actionSelect = actionContainer.querySelector('select');
  const action = actionSelect.value; // 'archive'

  if (action.length === 0) {
    alert('No action selected');
    return;
  }

  const checkboxes = checkboxContainer.querySelectorAll(selectors.checkedItemCheckbox);
  const campaignIDs = Array.from(checkboxes).map((input) => input.dataset.id);
  const params = 'action_name=' + action + '&ids=' + JSON.stringify(campaignIDs);

  const alertContainer = document.querySelector(selectors.alertContainer);
  alertContainer.classList.add("d-none"); // hide alert

  Rails.ajax({
    type: 'patch',
    url: submitUrl,
    data: params,
    success(response) {
      alertContainer.textContent = "Campaigns were successfully archived.";
      alertContainer.classList.add("alert-success");
      alertContainer.classList.remove("alert-danger", "d-none");
      // Remove selected <tr>s from the table
      checkboxes.forEach((checkbox) => { checkbox.closest('tr').remove() });
      selectAllCheckbox.checked = false;
      selectAllCheckbox.indeterminate = false;
    },
    error(response) {
      alertContainer.textContent = 'An error occured while saving the items. Please try again.';
      if (response.message) {
        alertContainer.textContent += ' Details: ' + response.message;
      }
      alertContainer.classList.add("alert-danger");
      alertContainer.classList.remove("alert-success", "d-none");
    }
  });
}
