// Applies nice formatting to JSON log content.

import prettyPrintJson from '../lib/theme/js/prettyPrintJson';

const selectors = {
  jsonContainer: "#pretty-print-json-container",
  jsonData: "#pretty-print-json",
};

document.addEventListener("turbolinks:load", () => {
  const jsonContainer = document.querySelector(selectors.jsonContainer);
  if (!jsonContainer) { return; }

  const codeBlocks = document.querySelectorAll(selectors.jsonData);

  codeBlocks.forEach((codeBlock) => {
    try {
      const resyncLogs = JSON.parse(codeBlock.textContent);
      codeBlock.innerHTML = prettyPrintJson.json.prettyPrint(resyncLogs);
    } catch (e) {
      console.error("Error parsing resync log data", e);
    }
  });
});
