import Sortable from 'sortablejs';
import Rails from "@rails/ujs";
import $ from 'jquery';

// To make items reordarable:
// Wrap the entire group to `div.reorderable-group` container.
// Add `data-reorder-path` attribute to the container, and implement the server-side controller action.
// Add `.reorderable-items` class to the item list.
// Add `.reorderable-item` class to the list items.
// Add `data-id` attribute to each item.
// Add `button.unlock-reordering`, `button.save-reordering` and `button.cancel-reordering` buttons.
// Add `div.error.d-none` container for errors.
// Add `div.success.d-none` container with a success message (optional).
// Use `.hide-when-reordering` class to hide elements in the reordering mode.
// Use `.show-when-reordering` class to only display elements in the reordering mode.

// Expected markup:
// <div class="reorderable-group" data-reorder-path="..." >
//   <button type="button" class="unlock-reordering">Reorder</button>
//   <div class="error alert alert-success d-none"></div>
//   <div class="success alert alert-danger d-none"></div>
//   <button type="button" class="save-reordering">Save</button>
//   <button type="button" class="cancel-reordering">Cancel</button>
//   <div class="reorderable-items">
//     <div class="reorderable-item" data-id="111">...</div>
//     <div class="reorderable-item" data-id="222">...</div>
//     <div class="reorderable-item" data-id="333">...</div>
//   </div>
// </div>
// Note: tables and ul/ol can also be used as `.reorderable-items` elements.

const selectors = {
  reorderableGroup: '.reorderable-group',
  reorderableItems: '.reorderable-items',
  unlockButton: 'button.unlock-reordering',
  saveButton: 'button.save-reordering',
  cancelButton: 'button.cancel-reordering',
  spinner: '.spinner',
  hide: ".hide-when-reordering",
  show: ".show-when-reordering",
  error: ".error",
  success: ".success",
}

document.addEventListener("turbolinks:load", () => {
  const sortableGroups = document.querySelectorAll(selectors.reorderableGroup);

  $(selectors.saveButton).hide();
  $(selectors.show).hide();

  sortableGroups.forEach((sortableGroup) => {
    const sortable = initSortableList(sortableGroup);

    const unlockButton = sortableGroup.querySelector(selectors.unlockButton);
    if (unlockButton) {
      unlockButton.addEventListener("click", (event) => {
        event.preventDefault();
        enableDragAndDrop(sortable, sortableGroup);
      });
    }
    
    const saveButton = sortableGroup.querySelector(selectors.saveButton);
    const cancelButton = sortableGroup.querySelector(selectors.cancelButton);

    saveButton.addEventListener("click", (event) => {
      event.preventDefault();
      submitSortable(sortableGroup, sortable, saveButton, cancelButton);
    });

    cancelButton.addEventListener("click", (event) => {
      event.preventDefault();
      disableDragAndDrop(sortable, sortableGroup);
    });
  });

});

function initSortableList(sortableGroup) {
  const sortableList = sortableGroup.querySelector(selectors.reorderableItems);
  return Sortable.create(sortableList, {
    dataIdAttr: 'data-id',
    animation: 300,
    disabled: true,
  });
}

function submitSortable(sortableGroup, sortable, saveButton, cancelButton) {
  const submitPath = sortableGroup.dataset.reorderPath;
  const orderedIds = sortable.toArray();
  const params = 'ordered_ids=' + JSON.stringify(orderedIds);

  saveButton.setAttribute("disabled", "true");

  $(selectors.spinner).show();
  saveButton.textContent = "Applying changes...";
  cancelButton.setAttribute("disabled", "true");

  Rails.ajax({
    type: 'patch',
    url: submitPath,
    data: params,
    success(response) {
      disableDragAndDrop(sortable, sortableGroup);
      toggleSuccessMessage(sortableGroup, true);
    },
    error(response) {
      const errorDiv = sortableGroup.querySelector(selectors.error);
      errorDiv.textContent = 'An error occured while saving the items. Please refresh the page and try again. Details: ' + response.error;
      errorDiv.classList.remove("d-none");
    },
    complete() {
      saveButton.removeAttribute("disabled");
      $(selectors.spinner).hide();
      saveButton.textContent = "Publish Changes";
      cancelButton.removeAttribute("disabled");
    }
  })
}

function enableDragAndDrop(sortable, sortableGroup) {
  toggleSuccessMessage(sortableGroup, false);
  sortable.option("disabled", false);
  showReorderingControls(sortableGroup);
}

function disableDragAndDrop(sortable, sortableGroup) {
  sortable.option("disabled", true);
  hideReorderingControls(sortableGroup);

  const errorDiv = sortableGroup.querySelector(selectors.error);
  errorDiv.classList.add("d-none");
}

// jQuery $.hide() and $.show() methods preserve the original element .display property
// (block, inline, etc), so jQuery here is a conscious choice.
function showReorderingControls(sortableGroup) {
  $(sortableGroup).addClass("reordering-enabled");

  $(sortableGroup).find(selectors.unlockButton).hide();
  $(sortableGroup).find(selectors.saveButton).show();
  $(sortableGroup).find(selectors.hide).hide();
  $(sortableGroup).find(selectors.show).show();
}

function hideReorderingControls(sortableGroup) {
  $(sortableGroup).removeClass("reordering-enabled");

  $(sortableGroup).find(selectors.unlockButton).show();
  $(sortableGroup).find(selectors.saveButton).hide();
  $(sortableGroup).find(selectors.hide).show();
  $(sortableGroup).find(selectors.show).hide();
}

// Pass show = true to show a message, or show = false to hide the message.
function toggleSuccessMessage(sortableGroup, show) {
  const successAlert = sortableGroup.querySelector(selectors.success);
  if (!successAlert) { return; }

  if (show) { successAlert.classList.remove("d-none"); }
  else { successAlert.classList.add("d-none"); }
}
