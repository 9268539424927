const selectors = {
  form_selector: '.simple_form.api-feed',
  title_field_selector: '.title_selector',
  feed_type_selector: '.feed_type_selector',
  marketing_card_group_selector: '.marketing_card_group_selector',
  tag_selector: '.tag_selector',
  applicaster_api_feed_formatted_url_container_selector: '#applicaster_api_feed_formatted_url_container'
};

const hideFormattedUrlContainer = () => {
  const formattedUrlContainer = document.querySelector(selectors.applicaster_api_feed_formatted_url_container_selector);
  if (!formattedUrlContainer) { return; }
  formattedUrlContainer.style.display = 'none';
}

const updateAPIFeedFields = (feedTypeSelect) => {
  const selectedOption = feedTypeSelect.selectedOptions[0];
  if (!selectedOption) { return; }

  const tagField = document.querySelector(selectors.tag_selector);
  if (!tagField) { return; }

  const marketingCardGroupField = document.querySelector(selectors.marketing_card_group_selector);
  const enabledTagStatuses = ["video", "upcoming", "live", "past"]
  if (!marketingCardGroupField) { return; }

  if (selectedOption.value === 'marketing_campaign') {
    tagField.disabled = true;
    tagField.parentElement.style.display = 'none';

    marketingCardGroupField.disabled = false;
    marketingCardGroupField.parentElement.style.display = 'block';
  } else if (enabledTagStatuses.includes(selectedOption.value)) {
    tagField.disabled = false;
    tagField.parentElement.style.display = 'block';

    marketingCardGroupField.disabled = true;
    marketingCardGroupField.parentElement.style.display = 'none';
  } else {
    tagField.disabled = true;
    tagField.parentElement.style.display = 'none';

    marketingCardGroupField.disabled = true;
    marketingCardGroupField.parentElement.style.display = 'none';
  }
};



document.addEventListener("turbolinks:load", () => {
  const feedTypeSelect = document.querySelector(selectors.feed_type_selector);
  if (!feedTypeSelect) { return; }

  feedTypeSelect.addEventListener(
    'change',
    ({ target }) => {
      updateAPIFeedFields(target);
      hideFormattedUrlContainer();
    }
  );

  updateAPIFeedFields(feedTypeSelect);

  [selectors.title_field_selector, selectors.tag_selector, selectors.marketing_card_group_selector].forEach( (selector) =>{
    const field = document.querySelector(selector);
    if(field) {
      field.addEventListener(
        'keyup',
        () => hideFormattedUrlContainer()
      );
      field.addEventListener(
        'change',
        () => hideFormattedUrlContainer()
      );
    }
  })
  
});