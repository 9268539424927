import Rails from '@rails/ujs';

document.addEventListener("turbolinks:load", function () {

    Rails.initRemoteInput = function(input){
        input.addEventListener('change', (e) => {
            var targetURL = e.target.dataset.url;
            console.log("sending GET to", targetURL);
            e.target.disabled = true
            Rails.ajax({
                // TODO: We should be using PATCH method here. GET should not modify the data.
                type: "GET",
                url: targetURL,
                success: function (response) {
                    e.target.disabled = false
                    console.log(response);
                },
                error: function (response) {
                    e.target.disabled = false
                    //on error we should invert the last check change
                    e.target.checked = !e.target.checked
                    console.error(response);
                    alert("Change could not be made! ERROR: " + response)
                }
            })
        });
    };

    //keep track of the last value so we can reset this if it fails
    var setLastValue = function (element) {
        element.dataset.lastValue = element.value
    };

    Rails.initRemoteSelect = function(input){
        setLastValue(input);
        input.addEventListener('change', (e) => {
            var targetURL = e.target.dataset.url;
            var selectionData = 'selected_value=' + e.target.value;
            console.log("sending POST of", selectionData, "to", targetURL);
            e.target.disabled = true
            Rails.ajax({
                type: "POST",
                url: targetURL,
                data: selectionData,
                success: function (response) {
                    e.target.disabled = false
                    setLastValue(e.target);
                    // console.log(response);
                },
                error: function (response) {
                    e.target.disabled = false
                    e.target.value = e.target.dataset.lastValue
                    console.error(response);
                    // alert("Change could not be made! ERROR: " + response)
                }
            })
        });
    };

    //initialize these by default.
    document.querySelectorAll("input.remote").forEach((input) => Rails.initRemoteInput(input));
    document.querySelectorAll("select.remote").forEach((input) => Rails.initRemoteSelect(input));
});
