import $ from 'jquery';
import 'select2';

const selectors = {
  deeplinkModal: '#deeplinkModal',
  deeplinkContentTab: 'div#form-content',
  deeplinkForm: "form.deeplink",
  mediaTypeInput: 'input[name="deeplink[media_type]"]',
  mediaTeamCodeInput: 'input[name="deeplink[team_code]"]',
  mediaLeagueCodeInput: 'input[name="deeplink[league_code]"]',
  mediaSelect: 'form.deeplink .media-content-select',
  mediaPreviewImage: '.content-image-preview img',
  addWideSearchPrefix: 'form.deeplink',
  universalUrlCheckBox: "#deeplink_has_universal_url",
  universalUrlContainer: ".universal-url-container",
  universalUrlFieldWrapper: ".universal-url-fields",
  fallbackUrlField: "input#deeplink_fallback_url"
};

document.addEventListener("turbolinks:load", () => {
  const firstForm = document.querySelector(selectors.deeplinkForm);
  if (!firstForm) { return; }

  initMediaSelects();
  initUniversalUrlCheckBox();
});

function initMediaSelects() {
  const contentTab = document.querySelector(selectors.deeplinkContentTab);
  if (!contentTab) { return; }

  const searchUrl = contentTab.dataset.mediaSearchUrl;

  const mediaSelects = document.querySelectorAll(selectors.mediaSelect);
  mediaSelects.forEach((mediaSelect) => {
    initMediaSelectSearch(mediaSelect, searchUrl);
    initMediaSelectPreview(mediaSelect);
  });
}

function buildOptions(searchUrl, mediaType, searchAppWideToggle) {
  let options = {
    theme: "bootstrap",
    placeholder: "Type to search...",
    ajax: {
      url: searchUrl,
      data: (params) => {
        params.media_type = mediaType;
        params.search_app_wide = searchAppWideToggle;
        return params;
      },
      dataType: 'json',
      delay: 250,
      processResults: function (data) {
        const options = data.map((m) => ({ id: m.id, text: formattedMediaTitle(m), imageUrl: m.image_url, teamCode: m.team_code, leagueCode: m.league_code }));
        return { results: options };
      }
    }
  };

  // If we are on Campaign/Card page where deeplink form is open as a modal,
  // override Select2 parent according to the official instructions:
  // https://select2.org/troubleshooting/common-problems#select2-does-not-function-properly-when-i-use-it-inside-a-bootst
  if ($(selectors.deeplinkModal).length) {
    options.dropdownParent = $(selectors.deeplinkModal);
  }
  return options;
}

function initMediaSelectSearch(mediaSelect, searchUrl) {
  const mediaType = $(mediaSelect).parents('form').find(selectors.mediaTypeInput).val();
  const searchAppWide = $(selectors.addWideSearchPrefix + ' #app_wide_search_' + mediaType);

  $(searchAppWide).on('change', (val) => {
    if (val.target.id === 'app_wide_search_' + mediaType) {
      const options = buildOptions(searchUrl, mediaType, val.target.checked);
      $(mediaSelect).select2(options);
    }
  });
  const options = buildOptions(searchUrl, mediaType, searchAppWide.checked);
  $(mediaSelect).select2(options);
}

function formattedMediaTitle(media) {
  return media.starts_at ? `${media.title} (LIVE)` : media.title;
}

function initMediaSelectPreview(mediaSelect) {
  const selectedOption = mediaSelect.selectedOptions[0];

  if (selectedOption) {
    updateMediaPreviewAndMetadata(mediaSelect, selectedOption.dataset);
  }

  $(mediaSelect).on('select2:select', (event) => {
    updateMediaPreviewAndMetadata(mediaSelect, event.params.data);
  });
}

function updateMediaPreviewAndMetadata(mediaSelect, data) {
  const mediaPreviewImage = mediaSelect.closest('form').querySelector(selectors.mediaPreviewImage);
  const mediaTeamCodeInput = mediaSelect.closest('form').querySelector(selectors.mediaTeamCodeInput);
  const mediaLeagueCodeInput = mediaSelect.closest('form').querySelector(selectors.mediaLeagueCodeInput);

  if (data) {
    if (data.imageUrl) {
      mediaPreviewImage.src = data.imageUrl;
      mediaPreviewImage.classList.remove("d-none");
    } else {
      mediaPreviewImage.src = "";
      mediaPreviewImage.classList.add("d-none");
    }

    if (data.teamCode) {
      mediaTeamCodeInput.value = data.teamCode;
    }

    if (data.leagueCode) {
      mediaLeagueCodeInput.value = data.leagueCode;
    }
  }
}

function initUniversalUrlCheckBox() {
  document.querySelectorAll(selectors.universalUrlCheckBox).forEach((checkbox) => {
    checkbox.addEventListener("change", () => { toggleUniversalUrlCheckBox(checkbox) });
  })
}

function toggleUniversalUrlCheckBox(checkbox) {
  const parentContainer = checkbox.closest(selectors.universalUrlContainer);
  const fallbackUrlWrapper = parentContainer.querySelector(selectors.universalUrlFieldWrapper);
  const fallbackUrlField = fallbackUrlWrapper.querySelector(selectors.fallbackUrlField);

  if (checkbox.checked) {
    fallbackUrlWrapper.classList.remove("d-none");
    fallbackUrlField.required = true;
  } else {
    fallbackUrlWrapper.classList.add("d-none");
    fallbackUrlField.value = '';
    fallbackUrlField.required = false;
  }
}
