import Sortable from 'sortablejs';
import Rails from "@rails/ujs";
import $ from 'jquery';

// This script is similar to `reorder_items.js` but has some specifics:
// a) There is no "Reorder"/"Cancel" buttons that unleck/lock reordering. We are always in "reordering" mode.
// b) There is only one group of reorderable items on the page. No multiple "reordarable groups" support.
// c) There is additional functionality related to the Preview page (hide/unhide items, refresh the preview).

// Wrap the entire group to `div.screen-preview` container.
// Add `data-publish-path` attribute to the container, and implement the server-side controller action.
// Add `.reorderable-items` class to the item list.
// Add `.reorderable-item` class to the list items.
// Add `data-id` attribute to each item.
// Add `button#publish-button` button.
// Add `div.error.d-none` container for errors.
// Add `div.success.d-none` container with a success message (optional).

// Expected markup:
// <div class="screen-preview" data-publish-path="..." >
//   <div class="error alert alert-success d-none"></div>
//   <div class="success alert alert-danger d-none"></div>
//   <div class="reorderable-items">
//     <div class="reorderable-item" data-id="111">...</div>
//     <div class="reorderable-item" data-id="222">...</div>
//     <div class="reorderable-item" data-id="333">...</div>
//   </div>
// </div>
// <button type="button" id="publish-button">Publish</button>

const selectors = {
  previewContainer: '.screen-preview',
  reorderableItems: '.reorderable-items',
  reorderableItem:  '.reorderable-items .reorderable-item',
  itemCheckbox: '.item-checkbox',
  publishButton: '#publish-button',
  error: ".error",
  success: ".success",
  pacifier: ".pacifier",
  previewFrame: '#preview-frame',
  // publishingPacifier: '#publishing-pacifier',
  // publishBar: '#publish-bar',
}

let sortable; // Sortable JS object
let previewContainer;

document.addEventListener("turbolinks:load", () => {
  previewContainer = document.querySelector(selectors.previewContainer);
  if (!previewContainer) { return; }

  initReordering();
  refreshPreview(); // appends `preview=` param to the iframe url
});

function initReordering() {
  sortable = initSortableList();

  const publishButton = document.querySelector(selectors.publishButton);
  publishButton.addEventListener("click", (event) => {
    event.preventDefault();
    submitSortable(publishButton);
  });

  const checkboxes = previewContainer.querySelectorAll(selectors.itemCheckbox);
  checkboxes.forEach((checkbox) => {
    checkbox.addEventListener("change", (event) => {
      // Update data-visible attribute that is used for data submission.
      event.target.closest(selectors.reorderableItem).dataset.visible = event.target.checked;
      refreshPreview();
      changeTextColor(event);
    });

    // change text color at initializing
    if (checkbox.attributes.checked) {
      checkbox.parentElement.classList.replace("text-600", "text-dark");
    }
  });
}

function initSortableList() {
  const sortableList = previewContainer.querySelector(selectors.reorderableItems);
  return Sortable.create(sortableList, {
    dataIdAttr: 'data-id',
    animation: 300,
    onEnd: refreshPreview,
  });
}

function submitSortable(publishButton) {
  if (confirm("This will update your live app. Would you like to proceed?") !== true) { return; }

  const submitPath = previewContainer.dataset.publishPath;

  let orderedItems = []
  const items = previewContainer.querySelectorAll(selectors.reorderableItem);
  items.forEach((item) => {
    orderedItems.push({
      id: item.dataset.id,
      marketing: item.dataset.marketing,
      visible: item.dataset.visible,
    });
  });

  const params = 'ordered_items=' + JSON.stringify(orderedItems);

  publishButton.setAttribute("disabled", "true");
  publishButton.dataset.success = false;
  toggleSuccessMessage(false);
  toggleErrorMessage(false);
  togglePacifier(true);

  Rails.ajax({
    type: 'patch',
    url: submitPath,
    data: params,
    success(response) {
      // 'data-success' attribute is a hack to run system tests without pacifier sleep time.
      publishButton.dataset.success = true;
      setTimeout(() => {
        togglePacifier(false);
        toggleSuccessMessage(true);
        publishButton.removeAttribute("disabled");
      }, 8000);
    },
    error(response) {
      togglePacifier(false);
      toggleErrorMessage(true, 'An error occured while saving the items. Please refresh the page and try again. Details: ' + response.error);
      publishButton.removeAttribute("disabled");
    },
  })

  // showPacifier();
}

// Pass show = true to show the message, or show = false to hide the message.
function toggleSuccessMessage(show) {
  const successAlert = previewContainer.querySelector(selectors.success);
  if (show) { successAlert.classList.remove("d-none"); }
  else { successAlert.classList.add("d-none"); }
}

// Usage:
// toggleErrorMessage(true, "Something went wrong")
// toggleErrorMessage(false)
function toggleErrorMessage(show, message) {
  const errorDiv = previewContainer.querySelector(selectors.error);
  errorDiv.textContent = message;
  if (show) { errorDiv.classList.remove("d-none"); }
  else { errorDiv.classList.add("d-none"); }
}

// Pass true to show the spinner, or false to hide the spinner.
function togglePacifier(show) {
  const pacifier = previewContainer.querySelector(selectors.pacifier);
  if (show) { pacifier.classList.remove("d-none"); }
  else { pacifier.classList.add("d-none"); }
}

function refreshPreview() {
  toggleSuccessMessage(false);

  let orderedKeys = [];
  const items = previewContainer.querySelectorAll(selectors.reorderableItem);
  items.forEach((item) => {
    if (item.dataset.visible == "true") {
      orderedKeys.push(item.dataset.id);
    }
  });

  const payload = JSON.stringify(orderedKeys);

  const preview = document.querySelector(selectors.previewFrame);
  let url = new URL(preview.src);
  let params = new URLSearchParams(url.search);
  params.delete('preview');
  params.append('preview', payload);

  url.search = params.toString();
  preview.src = url.toString();
}

// function showPacifier() {
//   $(selectors.publishingPacifier).removeClass('d-none');

//   const multiplier = 80; // 100 = 10 seconds

//   let sum = 100
//   const numbers = []
//   for (let i = 0; i < multiplier / 10; i++) {
//     let randomNumber = Math.floor(Math.random() * sum * 0.5)
//     sum -= randomNumber < 0 ? 0 : randomNumber
//     sum = sum < 0 ? 0 : sum
//     numbers.push(randomNumber < 0 ? 0 : randomNumber)
//   }
//   numbers.push(sum)

//   // console.log(numbers);

//   numbers.forEach((number) => {
//     setTimeout(() => {
//       const currentValue = $(selectors.publishBar).attr('aria-valuenow');
//       $(selectors.publishBar).css('width', `${currentValue + number}%`);
//       $(selectors.publishBar).attr('aria-valuenow', currentValue + number);
//     }, number * multiplier);
//   });

//   setTimeout(() => {
//     $(selectors.publishingPacifier).addClass('d-none');
//     $(selectors.publishBar).css('width', '0%');
//     $(selectors.publishBar).attr('aria-valuenow', '0');
//   }, 100 * multiplier / 1.25);
// }

function changeTextColor(e) {
  const tokenList = e.target.parentElement.classList;
  if (e.target.checked) {
    tokenList.replace("text-600", "text-dark")
  } else {
    tokenList.replace("text-dark", "text-600")
  }
}
