import { Modal, Tab } from 'bootstrap';

const selectors = {
	cardForm: 'form.marketing_card',
	imageUrlInput: 'input#marketing_card_image_url',
	imagePreview: '.card-preview .preview',
	imageLibraryButton: "#card-image-library",
	imageLibraryModal: "#image-library-modal",
	imageLibraryItem: "a.image",
}

document.addEventListener("turbolinks:load", () => {
	const cardForm = document.querySelector(selectors.cardForm);
	if (!cardForm) { return; }

	cardForm.addEventListener("submit", formConfirmationAndValidation);

	initImageLibrary();
});

function formConfirmationAndValidation(submitEvent) {
	const form = submitEvent.srcElement;
	const formSubmitElements = form.querySelectorAll("input[type='submit']");
	const urlInput = form.querySelector("input.url");
	if (!urlInput) { return false; }

	if( "" === urlInput.value ){
		if(!confirm("Please note you did not add a card link URL, confirm this was intended.")) {
			submitEvent.preventDefault();
			//Rails' disable_with activates AFTER form submit, so we will remove it here so buttons
			//still work.
			formSubmitElements.forEach((el) => delete el.dataset.disableWith);
			return false;
		}
	}

	//because we may have set disable_with: null on a previous attempt, we need to add it back in.
	formSubmitElements.forEach((el) => el.dataset.disableWith = el.value);
}

function initImageLibrary() {
	const cardForm = document.querySelector(selectors.cardForm);
	const modalContainer = document.querySelector(selectors.imageLibraryModal);
	const modal = new Modal(modalContainer);

	const libraryButton	= document.querySelector(selectors.imageLibraryButton);
	libraryButton.addEventListener("click", (event) => {
		event.preventDefault();
		modal.show();
	})

	const items = modalContainer.querySelectorAll(selectors.imageLibraryItem);
	items.forEach((item) => {
		item.addEventListener("click", (event) => {
			event.preventDefault();
			setCardImage(item, cardForm);
			modal.hide();
		});
	});
}

function setCardImage(item, cardForm) {
	// Although the card field is called "image_url" and all naming is done for "image",
	// videos are supported, too.
	const media = item.querySelector('img') || item.querySelector('video');
	const imageUrl = media['src'];

	// Set card's image url field.
	const imageUrlInput = cardForm.querySelector(selectors.imageUrlInput);
	imageUrlInput.value = imageUrl;

	// Display a preview
	const cardImagePreview = cardForm.querySelector(selectors.imagePreview);
	cardImagePreview.src = imageUrl;
	cardImagePreview.classList.remove("d-none");
}
