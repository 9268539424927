import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";

const chartsContainerSelector = '#dashboard-charts';
const datePickerSelector = '#dashboard-datepicker';
const viewsClicksStackedBarSelector = '.views-clicks-stacked-bar';
const downloadsSelector = "[data-chart='installations']";
const usersChartSelector = '#users-chart';

document.addEventListener("turbolinks:load", () => {
  const container = document.querySelector(chartsContainerSelector);
  if (!container) { return; }

  initDatePicker();
  initMarketingCards();
  initDownloadsChart();
  initUsers();
});

function initDatePicker() {
  flatpickr(datePickerSelector, {
    mode: "range",
    showMonths: 2,
    dateFormat: "Y-m-d",
    altInput: true,
    altFormat: "M j",
    // minDate: new Date().fp_incr(-180), // 180 days ago
    maxDate: new Date().fp_incr(-1),
    // Use Flatpickr on mobile, too. https://flatpickr.js.org/mobile-support/
    disableMobile: true,
    // onChange: function(selectedDates, dateStr, instance) {
    //   if (selectedDates.length === 2) {
    //     // user has selected a date range, send AJAX if needed
    //   }
    // },
  });
}

function initDownloadsChart() {
  const canvas = document.querySelector(downloadsSelector);
  const data = JSON.parse(canvas.dataset.installations);

  return new Chart(canvas.getContext('2d'), {
    plugins: [ChartDataLabels],
    type: 'bar',
    data: {
      labels: ['Downloads', 'Updates'],
      datasets: [{
        data: data,
        backgroundColor: ['#27BCFD', '#27BCFD']
      }]
    },
    options: {
      responsive: true,
      indexAxis: 'y',
      plugins: {
        legend: {
          display: false
        },
        datalabels: {
          color: '#FFFFFF',
          font: {
            size: '16rem',
            weight: 'bold'
          }
        }
      }
    }
  });
}

function initMarketingCards() {
  const canvases = document.querySelectorAll(viewsClicksStackedBarSelector);

  canvases.forEach((canvas) => {
    const labels = JSON.parse(canvas.dataset.labels);
    const views = JSON.parse(canvas.dataset.views);
    const clicks = JSON.parse(canvas.dataset.clicks);
    const ctrs = JSON.parse(canvas.dataset.ctrs);  // CTR, click through rate

    const ctx = canvas.getContext('2d');
    return new Chart(ctx, {
      type: 'bar',
      data: {
        labels,
        datasets: [
          {
            label: 'Views',
            data: views,
            backgroundColor: '#2C7BE5',
            datalabels: { display: false }
          },
          {
            label: 'Clicks',
            data: clicks,
            backgroundColor: '#27BCFD',
          },
        ]
      },
      options: {
        indexAxis: 'y',
        responsive: true,
        scales: {
          x: { stacked: true },
          y: { stacked: true }
        },
        interaction: {
          mode: 'index',
        },
        plugins: {
          legend: {
            position: 'top',
          },
          tooltip: {
            callbacks: {
              footer: (tooltipItems) => `CTR ${ctrs[tooltipItems[0].dataIndex]}%`
            }
          }
        }
      }
    });
  });
}

function initUsers() {
  const canvas = document.querySelector(usersChartSelector);
  if (!canvas) { return; }

  const labels   = JSON.parse(canvas.dataset.labels);
  const sessions = JSON.parse(canvas.dataset.sessions);
  const users    = JSON.parse(canvas.dataset.users);
  const newUsers = JSON.parse(canvas.dataset.newUsers);
  const sessionsTotal = canvas.dataset.sessionsTotal;
  const usersTotal    = canvas.dataset.usersTotal;
  const newUsersTotal = canvas.dataset.newUsersTotal;

  const ctx = canvas.getContext('2d');
  return new Chart(ctx, {
    type: 'line',
    data: {
      labels: labels,
      datasets: [
        {
          label: `Sessions (total ${sessionsTotal})`,
          data: sessions,
          borderColor:     '#00D27A',
          backgroundColor: '#00D27A',
        },
        {
          label: `Users (total ${usersTotal})`,
          data: users,
          borderColor:     '#27BCFD',
          backgroundColor: '#27BCFD',
        },
        {
          label: `New Users (total ${newUsersTotal})`,
          data: newUsers,
          borderColor:     '#2C7BE5',
          backgroundColor: '#2C7BE5',
        }
      ]
    },
    options: {
      responsive: true,
      cubicInterpolationMode: 'monotone',
      plugins: {
        legend: {
          position: 'top',
        }
      }
    }
  });
}
