import $ from 'jquery';
import 'select2';

const selectors = {
  container: 'div#content-explorer',
  mediaSelect: '.media-select',
};

let searchUrl;
let mediaSelects;

document.addEventListener("turbolinks:load", () => {
  const container = document.querySelector(selectors.container);
  if (!container) { return; }

  searchUrl = container.dataset.mediaSearchUrl;
  mediaSelects = document.querySelectorAll(selectors.mediaSelect);

  mediaSelects.forEach((mediaSelect) => {
    initSearch(mediaSelect);
    initPreview(mediaSelect);
  });
});

function initSearch(mediaSelect) {
  const mediaType = mediaSelect.dataset.mediaType;
  const appWideFlag = mediaSelect.dataset.appWide;
  const options = buildOptions(mediaType, appWideFlag);
  $(mediaSelect).select2(options);
}

// Builds options for Search dropdowns
function buildOptions(mediaType, searchAppWideToggle) {
  let options = {
    theme: "bootstrap",
    placeholder: "Type to search...",
    ajax: {
      url: searchUrl,
      data: (params) => {
        params.media_type = mediaType;
        params.search_app_wide = searchAppWideToggle;
        return params;
      },
      dataType: 'json',
      delay: 250,
      processResults: function (data) {
        const options = data.map((m) => ({ id: m.id, text: m.title, details_url: m.details_url }));
        return { results: options };
      }
    }
  };

  return options;
}

// Specifies what happens when a user picks an option in the Search dropdown.
function initPreview(mediaSelect) {
  $(mediaSelect).on('select2:select', (event) => {
    const details_url = event.params.data?.details_url
    if (details_url) {
      window.location.href = details_url;
    } else {
      alert("Error: cannot display the media, please refresh the page and try again.");
    }
  });
}
