// These fields (checkboxes) are displayed on the Deep Link form,
// and on the Menu Item form.

const selectors = {
  container: ".js-web-url-fields",
  externalBrowserCheckbox: 'input.js-external-browser',
  inAppCheckbox: 'input.js-in-app',
};

document.addEventListener("turbolinks:load", () => {
  const container = document.querySelector(selectors.container);
  if (!container) { return; }

  const checkboxExternalBrowser = container.querySelector(selectors.externalBrowserCheckbox);
  const checkboxesInApp = container.querySelectorAll(selectors.inAppCheckbox);

  toggleInAppCheckboxes(checkboxExternalBrowser.checked, checkboxesInApp);
  checkboxExternalBrowser.addEventListener('change', (event) => {
    toggleInAppCheckboxes(event.target.checked, checkboxesInApp);
  });
});

function toggleInAppCheckboxes(hide, checkboxesInApp) {
  checkboxesInApp.forEach((checkbox) => {
    checkbox.disabled = hide;
    if (hide) { checkbox.checked = false; }
  });
}
