import $ from 'jquery';
import 'select2';

const personaSelectSelector = '#persona-select select';
const universalSelectSelector = '.js-select2';

document.addEventListener('turbolinks:load', () => {
  $(personaSelectSelector)
    .select2({
      dropdownCssClass: "persona-select-dropdown",
      placeholder: "Type to filter...",
      width: "100%",
    })
    .on('select2:open', () => {
      document.querySelector('input.select2-search__field').placeholder = "Type to filter...";
      document.querySelector('.select2-search__field').focus();
    })
    .on('select2:select', ({ params }) => window.location.href = params.data.id);

  $(universalSelectSelector).select2({
    width: "100%",
    closeOnSelect: false,
  });

  $(universalSelectSelector).on("select2:unselect", ({params: {originalEvent}}) => { 
    if (!originalEvent) { return; }

    originalEvent.stopPropagation();
  });
});

// prevents multiple initialization of select2 dropdown on turbolinks back/forth
document.addEventListener("turbolinks:before-cache", () => {
  $(personaSelectSelector).select2("destroy");
  $(universalSelectSelector).select2("destroy");
});
