const copyButtonSelector = '.copy-button';

document.addEventListener("turbolinks:load", () => {
  initCopyButton();
});

function initCopyButton() {
  const copyButton = document.querySelectorAll(copyButtonSelector);

  if (copyButton) {
    copyButton.forEach( btn => {
      btn.addEventListener("click", (e) => {
        e.preventDefault();
        var copyTarget = btn.dataset.target;
        var copyValue = document.querySelector(copyTarget)?.value;
        if (copyValue) {
          document.querySelector(copyTarget).select();
          navigator.clipboard.writeText(copyValue);
        } else {
          console.error("copy-button target element not found");
        };
      });
    });
  };
}
