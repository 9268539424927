import Rails from "@rails/ujs";
import { Modal, Tab } from 'bootstrap';
import $ from 'jquery';
import 'select2';

const selectors = {
  // Card/Campaign form
  cardForm: 'form.marketing_card',
  deeplinkUrlInput: 'input.deeplink-modal-input',
  deeplinkFieldsPlaceholder: '.js-form-placeholder',
  cardTitleInput: 'input#marketing_card_title',
  imageUrlInput: 'input#marketing_card_image_url',
  imagePreview: '.card-preview img',
  loadFromCMSButton: '#load-from-cms',
  // Deeplink form
  deeplinkModal: '#deeplinkModal',
  activeDeeplinkForm: '.tab-pane.active > form.deeplink',
  deeplinkTypeInput: 'input.deeplink-type',
  deeplinkMediaSelect: '.media-content-select',
  deeplinkFormSubmit: 'button#submit-deeplink',
}

document.addEventListener("turbolinks:load", () => {
  Rails.refreshCSRFTokens();  // a fix for ActionController::InvalidAuthenticityToken

  const urlInput = document.querySelector(selectors.deeplinkUrlInput);
  if (!urlInput) { return; }

  const modalContainer = document.querySelector(selectors.deeplinkModal);
  const deeplinkModal = new Modal(modalContainer);

  const submitButton = modalContainer.querySelector(selectors.deeplinkFormSubmit);
  submitButton.addEventListener("click", () => {
    submitForm(modalContainer, deeplinkModal, urlInput);
  });

  initLoadFromCMS(deeplinkModal, modalContainer);
});

function submitForm(modalContainer, deeplinkModal, urlInput) {
  const deeplinkForm = modalContainer.querySelector(selectors.activeDeeplinkForm);
  const parentFormPlaceholder = document.querySelector(selectors.deeplinkFieldsPlaceholder);

  // select all inputs except token on active modal form and clone them
  const inputs = cloneInputs(deeplinkForm);

  const request = new XMLHttpRequest();
  const formData = new FormData(deeplinkForm);

  // Success
  request.addEventListener("load", function(event) {
    if (request.status !== 200) {
      var error_message = 'Error setting Deeplink';
      //try and see if Rails gave us an error: message in the response hash.
      // eg. render json: { error: "Could not build deeplink! Please contact support." }, status: :bad_request
      try { error_message = JSON.parse(request.response).error } catch {};
      alert(error_message);
      return;
    }

    try {
      const response = JSON.parse(request.response);
      urlInput.value = response.deeplink_url;

      parentFormPlaceholder.innerHTML = "";
      parentFormPlaceholder.append(...inputs);

      // If a "Content" (media) deeplink is selected, prefill card title and image from this media.
      setCardFieldsFromContent(deeplinkForm);

      // Only close the dialog after getting a server response, otherwise a user may save the MC form
      // before the url field is updated.
      deeplinkModal.hide();
    } catch(e) {
      alert('Error setting deep link');  // JSON.parse failed
    }
  });

  // Error
  request.addEventListener("error", function( event ) {
    alert('Error setting Deeplink');
  });

  const url = deeplinkForm.getAttribute('action') + ".json";
  request.open("POST", url);
  request.send(formData);
}

function cloneInputs(form) {
  const inputs = [...form.querySelectorAll('input:not([name="authenticity_token"]),select')].map((item) => {
    const cloned = item.cloneNode(true);

    if (cloned.nodeName === "SELECT") {
      cloned.value = item.value; //  set "select" value manually, it's not part of html so not cloned automatically
    }
    return cloned;
  });
  return inputs;
}

// Fill in card title and image with the media title and image.
// This is only relevant for Marketing Cards with Content deep links.
function setCardFieldsFromContent(deeplinkForm) {
  const cardForm = document.querySelector(selectors.cardForm);
  if (!cardForm) { return; }  // this prefill in only for a Card form, not for a Campaign form.

  const deeplinkTypeInput = deeplinkForm.querySelector(selectors.deeplinkTypeInput);
  if ((!deeplinkTypeInput) || (deeplinkTypeInput.value !== "content")) {
    return;
  }

  // For the selected deep link media, fetch the image url and media title.
  const mediaSelectOption = $(deeplinkForm).find(selectors.deeplinkMediaSelect).select2('data')[0];
  if (!mediaSelectOption) { return; }
  const mediaTitle = mediaSelectOption.text;
  // The `.element.dataset.imageUrl` is a fallback for the "Edit Card" form where
  // there is already a media option selected. For some reason, in this case,
  // Select2 puts the value to a different place.
  const imageUrl = mediaSelectOption.imageUrl || mediaSelectOption.element.dataset.imageUrl;

  setCardImage(cardForm, imageUrl);
  setCardTitle(cardForm, mediaTitle);
}

// Fill in the card image_url (a hidden field), and display a preview.
function setCardImage(cardForm, imageUrl) {
  // Set card's image url field to the value from the selected media.
  const imageUrlInput = cardForm.querySelector(selectors.imageUrlInput);
  imageUrlInput.value = imageUrl;

  // Display a preview
  const cardImagePreview = cardForm.querySelector(selectors.imagePreview);
  cardImagePreview.src = imageUrl;
  cardImagePreview.classList.remove("d-none");
}

// Fill in the card image title.
function setCardTitle(cardForm, mediaTitle) {
  const cardTitleInput = cardForm.querySelector(selectors.cardTitleInput);
  const currentTitle = cardTitleInput.value;
  let replaceTitle;

  // If a card title is specified and is different from the media title, ask user's confirmation.
  if ((currentTitle.length === 0) || (currentTitle === mediaTitle)) {
    replaceTitle = true;
  } else {
    replaceTitle = confirm('Replace card title with "' + mediaTitle + '"?');
  }

  if (replaceTitle) {
    cardTitleInput.value = mediaTitle;
  }
}

// "Load From CMS" button pops up the deep link modal with the "Content" tab active.
// Then, a user selects an audio/video/etc, submits the deep link form,
// and the card image and title fields are autocompleted.
function initLoadFromCMS(deeplinkModal, modalContainer) {
  const loadFromCMSButton = document.querySelector(selectors.loadFromCMSButton);
  if (!loadFromCMSButton) { return; }

  loadFromCMSButton.addEventListener("click", (event) => {
    event.preventDefault();
    const contentTabPill = modalContainer.querySelector("#pill-content");
    const tab = new Tab(contentTabPill);
    tab.show();
    deeplinkModal.show();
  });
}

