const clearButtonSelector = 'button.clear-input';
const deeplinkFormPlaceholder = ".js-form-placeholder";

// "Clear" button clears an input placed in the same div.
document.addEventListener("turbolinks:load", () => {
  const buttons = document.querySelectorAll(clearButtonSelector);

  buttons.forEach((button) => {
    const input = button.parentNode.querySelector('input');
    if (!input) { return; }
    button.addEventListener("click", () => {
      input.value = '';
      input.classList.remove('is-valid');

      // clear deeeplink nested form values
      document.querySelector(deeplinkFormPlaceholder).innerHTML = "";
    });
  });
});
