import Rails from "@rails/ujs";
import $ from 'jquery';

const selectors = {
  form: 'form.js-marketing-campaign',
  screenLocation: '#marketing_campaign_component_lookup_key',
  position: '#marketing_campaign_user_friendly_position',
  cardType: "#marketing_campaign_marketing_media_format_id",
  filterByTagsContainer: "div.marketing_campaign_filter_by_tags",
  mediaTagsContainer: "div.marketing_campaign_media_tags",
  cardsPerRowContainer: "div.marketing_campaign_cards_per_row",
  titleBlockHeading: "#advancedFieldsHeading",
  titleBlockContent: '#advancedFields',
  audiencesContainer: '.audiences-container',
  audiencesFormContainer: '.audience_form_container',
  audienceTypeFormItem: '.audience_type_form_item',
  audienceAddButton: '.audience_add_button',
  audienceTrashButton: '.audience_trash_button',
  headerRadioOptions: "input[name='marketing_campaign[cta_header_type]']",
  activeHeaderRadio: "input[name='marketing_campaign[cta_header_type]']:checked",
  textHeaderContainer: '#textHeaderContainer',
  customHeaderContainer: '#customHeaderContainer',
  ctaUrlLabel: "label[for='marketing_campaign_cta_url']",
};

const bootstrap = window.bootstrap || {};

const updateAvailablePositions = () => {
  const form = document.querySelector(selectors.form);
  const screenLocationSelect = form.querySelector(selectors.screenLocation);
  const selectedScreenOption = screenLocationSelect.selectedOptions[0];
  if (!selectedScreenOption) { return; }

  const positionSelect = form.querySelector(selectors.position);
  const positions = JSON.parse(selectedScreenOption.dataset.positions);

  positionSelect.innerHTML = "";
  for (const positionAttrs of positions) {
    const option = document.createElement('option');
    option.text = positionAttrs[0];
    option.value = positionAttrs[1];

    if (positionAttrs[2] && positionAttrs[2]["disabled"]) {
      option.disabled = true;
    }

    positionSelect.add(option);
  }
};

const toggleFilterByTags = () => {
  const form = document.querySelector(selectors.form);
  const screenLocationSelect = form.querySelector(selectors.screenLocation);
  const selectedScreenOption = screenLocationSelect.selectedOptions[0];
  if (!selectedScreenOption) { return; }

  const filterByTagsContainer = form.querySelector(selectors.filterByTagsContainer);
  const filterByTagsInput = filterByTagsContainer.querySelector('input');

  if (selectedScreenOption.dataset.gameOrEvent === 'true') {
    filterByTagsInput.disabled = false;
    filterByTagsContainer.classList.remove("d-none");
  } else {
    filterByTagsInput.disabled = true;
    filterByTagsContainer.classList.add("d-none");
  }
}

const onScreenLocationChange = () => {
  updateAvailablePositions();
  toggleFilterByTags();
};

const toggleCardsPerRow = () => {
  const form = document.querySelector(selectors.form);
  const selectedCardType = form.querySelector(selectors.cardType).selectedOptions[0];
  const cardsPerRowContainer = form.querySelector(selectors.cardsPerRowContainer);
  const cardsPerRowSelect = cardsPerRowContainer.querySelector('select');

  if (selectedCardType && (selectedCardType.dataset.grid === 'true')) {
    cardsPerRowSelect.disabled = false;
    cardsPerRowContainer.classList.remove("d-none");
  } else {
    cardsPerRowSelect.disabled = true;
    cardsPerRowContainer.classList.add("d-none");
  }
}

const toggleMediaTagsField = (enabled) => {
  const form = document.querySelector(selectors.form);
  const selectedCardType = form.querySelector(selectors.cardType).selectedOptions[0];
  const mediaTagsSelect = form.querySelector(selectors.mediaTagsContainer);

  if (selectedCardType && (selectedCardType.dataset.tag === 'true')) {
    mediaTagsSelect.disabled = false;
    mediaTagsSelect.classList.remove("d-none");
  } else {
    mediaTagsSelect.disabled = true;
    mediaTagsSelect.classList.add("d-none");
  }
}

const togglePopover = (show) => {
  if (typeof popover === 'undefined' || !popover) { return; }
  show ? popover.enable() : popover.disable();
};

const toggleTitleCtaBlock = (enabled) => {
  const collapseButton = document.querySelector(selectors.titleBlockHeading).querySelector('button');

  // disable button that opens CTA block accordion
  collapseButton.toggleAttribute('disabled', !enabled);

  // make heading and button transparent for pointer events so Chrome/Safari could show popover on hover
  collapseButton.classList.toggle('pe-none', !enabled);

  // when block should be disabled - ensure that accordion is collapsed
  const collapse = bootstrap.Collapse.getInstance(selectors.titleBlockContent);
  if (collapse && !enabled) {
    collapse.hide();
  }

  togglePopover(!enabled);
};

const onCardTypeChange = ({ target }) => {
  const selectedOption = target.selectedOptions[0];
  if (!selectedOption) {
    toggleTitleCtaBlock(true);
  }

  toggleTitleCtaBlock(selectedOption.dataset.ctaEnabled === 'true');
  toggleCardsPerRow();
	toggleMediaTagsField();
};

const initTitleCtaBlock = (form) => {
  const selectedCardType = form.querySelector(selectors.cardType).selectedOptions[0];
  const enableCTA = selectedCardType.dataset.ctaEnabled === 'true';

  const popover = new bootstrap.Popover(
    document.querySelector(selectors.titleBlockHeading),
    {
      placement: 'bottom',
      trigger: 'hover focus',
      content: "Title & CTA options are not available with video type cards",
    }
  );

  toggleTitleCtaBlock(enableCTA);

  if (enableCTA) { popover.disable(); }

  return popover;
};

const newAudienceFormItem = (target) => {

  const url = target.getAttribute('data-url');
  const audienceType = target.value || 0;

  Rails.ajax({
    type: "GET",
    url: url,
    data: `audience_type=${audienceType}`,
    success: function (response) {
      const content = $(response).find(selectors.audiencesFormContainer);
      const destination = target.getAttribute('data-parent');
      if (destination) {
        $(document).find(destination).replaceWith(content);
      } else {
        $(selectors.audiencesContainer).append(content);
      }
      setupAudienceFormListeners();
    }
  });

}

const removeAudienceFormItem = (target) => {
  target.closest(selectors.audiencesFormContainer).remove();
  //If we remove the last form item, we want to add a clean one automatically.
  //As well as a courtesy, this lets the form submission after delete clear all tags.
  if( !document.querySelector(selectors.audiencesFormContainer) ){
    newAudienceFormItem( document.querySelector(selectors.audienceAddButton) );
  }
}

const setupAudienceFormListeners = () => {
  $(selectors.audienceTypeFormItem).off();
  $(selectors.audienceTypeFormItem).on('change', ({ target }) => newAudienceFormItem(target));
  $(selectors.audienceAddButton).off();
  $(selectors.audienceAddButton).on('click', ({ target }) => newAudienceFormItem(target));
  $(selectors.audienceTrashButton).off();
  $(selectors.audienceTrashButton).on('click', ({ target }) => removeAudienceFormItem(target));
}

const toggleHeaderOptions = (selectedOption) => {
  const customHeaderContainer = document.querySelector(selectors.customHeaderContainer);
  const textHeaderContainer = document.querySelector(selectors.textHeaderContainer);
  const ctaUrlLabel = document.querySelector(selectors.ctaUrlLabel);

  if (selectedOption == 'custom') {
    customHeaderContainer.classList.remove("d-none");
    textHeaderContainer.classList.add("d-none");
    ctaUrlLabel.textContent = "Header Link";
  } else {
    customHeaderContainer.classList.add("d-none");
    textHeaderContainer.classList.remove("d-none");
    ctaUrlLabel.textContent = "CTA Link";
  }
}

const initHeaderOptions = () => {
  const activeHeaderRadio = document.querySelector(selectors.activeHeaderRadio);
  if (activeHeaderRadio) {
    toggleHeaderOptions(activeHeaderRadio.value);
  }

  const headerRadioOptions = document.querySelectorAll(selectors.headerRadioOptions);
  for (let radio of headerRadioOptions) {
    radio.addEventListener('change', (event) => {
      toggleHeaderOptions(event.target.value);
    });
  }
}

let popover = null;
document.addEventListener("turbolinks:load", () => {
  const form = document.querySelector(selectors.form);
  if (!form) { return; }

  const screenLocationSelect = form.querySelector(selectors.screenLocation);
  screenLocationSelect.addEventListener(
    'change',
    ({ target }) => onScreenLocationChange()
  );

  popover = initTitleCtaBlock(form);
  form.querySelector(selectors.cardType)
    .addEventListener('change', onCardTypeChange);

  toggleCardsPerRow();
  setupAudienceFormListeners();
  initHeaderOptions();
  toggleMediaTagsField();
  toggleFilterByTags();
});
